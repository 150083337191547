import { styled } from '@mui/system'

export const LottieWrap = styled('div')(({ theme }) => ({
  '& svg': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover'
  }
}))
