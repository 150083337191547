import * as React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { Box, Container } from '@mui/material'
import { SectionBg, Section, SectionDescription, TitleWhite, SectionBgMobile } from '../common/Typography'
import { fadeUp, fadeLeft } from '../../src/animationVariants'
import Lottie from 'lottie-react'
import animationData from '../../public/animations/bg-section-set-blue-01.json'
import { LottieWrap } from '../common/LottieStyled'
import CoverImg from '../../public/images/bg-section-set-blue-01_v2.jpg'
import CoverImgMobile from '../../public/images/bg-section-set-blue-01-mob.jpg'
import Parallax from '../common/Parallax'

const interactivity = {
  mode: 'scroll',
  actions: [
    {
      visibility: [0, 0.3],
      type: 'play',
      frames: [0]
    }
  ]
}

export default function InteractiveFormCoaching ({
  interactiveFormCoachingRef
}) {
  return (
    <Section ref={interactiveFormCoachingRef}>
      <Container maxWidth='xl' sx={{ position: 'relative' }}>
        <Box>
          <Box sx={{ maxWidth: { xs: '100%', md: '38%', position: 'relative', zIndex: 10 } }}>
            <motion.div
              variants={fadeLeft}
              initial={{ opacity: 0, x: 100 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <TitleWhite>Interactive Form Coaching</TitleWhite>
            </motion.div>
            <motion.div
              variants={fadeUp}
              initial={{ opacity: 0, y: 100 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <SectionDescription>Altis uses advanced AI and computer vision to help you understand how your body is moving during exercise, and what you need to do to improve form and movement performance. Think about Altis like GPS for your body.</SectionDescription>
            </motion.div>
          </Box>
        </Box>
      </Container>
      <SectionBg>
        <Image
          alt='Altis'
          src={CoverImg}
          layout='fill'
          objectFit='contain'
          quality={80}
        />
        <LottieWrap>
          <Parallax offset={80} top='80px'>
            <Lottie animationData={animationData} interactivity={interactivity} speed={1} />
          </Parallax>
        </LottieWrap>
      </SectionBg>
      <SectionBgMobile>
        <Image
          alt='Altis'
          src={CoverImgMobile}
          layout='responsive'
          objectFit='contain'
          quality={80}
        />
      </SectionBgMobile>
    </Section>
  )
}
