import Image from 'next/image'
import { motion } from 'framer-motion'
import { Box, Container } from '@mui/material'
import { SectionBg, Section, SectionDescription, TitleWhite, SectionBgMobile } from '../common/Typography'
import { fadeUp, fadeLeft } from '../../src/animationVariants'
import Lottie from 'lottie-react'
import animationData from '../../public/animations/bg-section-set-blue-03.json'
import { LottieWrap } from '../common/LottieStyled'
import CoverImg from '../../public/images/bg-section-set-blue-03_v2.jpg'
import CoverImgMobile from '../../public/images/bg-section-set-blue-03-mob.jpg'

const interactivity = {
  mode: 'scroll',
  actions: [
    {
      visibility: [0, 0.3],
      type: 'play',
      frames: [0]
    }
  ]
}

export default function SleekCompactConsole () {
  return (
    <Section sx={{ alignItems: 'flex-start' }}>
      <Container maxWidth='xl' sx={{ position: 'relative' }}>
        <Box sx={{ }}>
          <Box sx={{ maxWidth: { xs: '100%', md: '38%' }, position: 'relative', zIndex: 10 }}>
            <motion.div
              variants={fadeLeft}
              initial={{ opacity: 0, x: 50 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <TitleWhite>Sleek, Compact Console</TitleWhite>
            </motion.div>
            <motion.div
              variants={fadeUp}
              initial={{ opacity: 0, y: 100 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <SectionDescription>Whether home is in the city, the suburbs, or the country, no one wants their space to look like a gym. That’s why we engineered Altis to work with any screen in your home; and at the size of a small soundbar, you just might forget Altis is even there.</SectionDescription>
            </motion.div>
          </Box>
        </Box>
      </Container>
      <SectionBg>
        <Image
          alt='Altis'
          src={CoverImg}
          layout='fill'
          objectFit='contain'
          quality={100}
        />
        <LottieWrap>
          <Lottie
            animationData={animationData}
            interactivity={interactivity}
            speed={1}
          />
        </LottieWrap>
      </SectionBg>
      <SectionBgMobile>
        <Image
          alt='Altis'
          src={CoverImgMobile}
          layout='responsive'
          objectFit='contain'
          quality={80}
        />
      </SectionBgMobile>
    </Section>
  )
}
