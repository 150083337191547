import * as yup from 'yup'

const nameRegExp = /^[A-Za-z0-9_ ]+$/

export const formSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
})

export const formSchemaAssessments = yup.object({
  firstName: yup.string()
    .required('First Name is required')
    .trim('Please use only alphanumeric characters')
    .min(3, 'First Name must be at least 3 characters')
    .max(20, 'Last Name must be at most 20 characters')
    .matches(nameRegExp, { message: 'Please use only alphanumeric characters' }),
  lastName: yup.string()
    .required('Last Name is required')
    .trim('Please use only alphanumeric characters')
    .min(3, 'Last Name must be at least 3 characters')
    .max(20, 'Last Name must be at most 20 characters')
    .matches(nameRegExp, { message: 'Please use only alphanumeric characters' }),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
})

export const formSchemaRSVP = yup.object({
  firstName: yup.string()
    .required('First Name is required')
    .trim('Please use only alphanumeric characters')
    .min(3, 'First Name must be at least 3 characters')
    .matches(nameRegExp, { message: 'Please use only alphanumeric characters' }),
  lastName: yup.string()
    .required('Last Name is required')
    .trim('Please use only alphanumeric characters')
    .min(3, 'Last Name must be at least 3 characters')
    .matches(nameRegExp, { message: 'Please use only alphanumeric characters' }),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
})
