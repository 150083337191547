
import Head from 'next/head'

export default function MetaHome () {
  return (
    <Head>
      <title>Altis - AI Personal Trainer | Home</title>
      <meta name='title' content='Altis - AI Personal Trainer | Home' />
      <meta name='description' content="Welcome to Altis, your AI Personal Trainer. Based on your body's assessment data, and of course your personal goals and schedule, Altis creates the optimal exercise regimen for your body. AI measure, track, and evaluate your exercise performance, and create a personalized path that adapts to you, so that you get the absolute most out of every single rep, every set, every exercise, every day. For life." />
      <meta name='keywords' content='Altis, personalization, trainer, gym, fitness, AI, coach, body, console, real-time, exercises, track, feedback, intuitive, interactive, console, subscription, health, body, arms, legs, joints, muscles, rep, set' />

      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://altis.ai/' />
      <meta property='og:title' content='Altis - AI Personal Trainer | Home' />
      <meta property='og:description' content="Welcome to Altis, your AI Personal Trainer. Based on your body's assessment data, and of course your personal goals and schedule, Altis creates the optimal exercise regimen for your body. AI measure, track, and evaluate your exercise performance, and create a personalized path that adapts to you, so that you get the absolute most out of every single rep, every set, every exercise, every day. For life." />
      <meta property='og:image' content='https://storage.googleapis.com/altis_ai-cdn/www/images/seo/home.jpg' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='https://altis.ai/' />
      <meta property='twitter:title' content='Altis - AI Personal Trainer | Home' />
      <meta property='twitter:description' content="Welcome to Altis, your AI Personal Trainer. Based on your body's assessment data, and of course your personal goals and schedule, Altis creates the optimal exercise regimen for your body. AI measure, track, and evaluate your exercise performance, and create a personalized path that adapts to you, so that you get the absolute most out of every single rep, every set, every exercise, every day. For life." />
      <meta property='twitter:image' content='https://storage.googleapis.com/altis_ai-cdn/www/images/seo/home.jpg' />
    </Head>
  )
}
