import { styled } from '@mui/system'
import Link from './Link'

export const ContentWrap = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  '& p': {
    lineHeight: 1.7,
    margin: '0 0 20px'
  },
  '& a': {
    color: '#3D80BA'
  },
  '& a:hover': {
    textDecoration: 'none'
  },
  '& ol': {
    counterReset: 'item',
    paddingLeft: 0,
    marginBottom: 20
  },
  '& ol ol': {
    padding: '20px 20px 0'
  },
  '& ol li': {
    display: 'block',
    marginBottom: 20,
    lineHeight: 1.5
  },
  '& ol li:before': {
    content: 'counters(item, ".") ". "',
    counterIncrement: 'item'
  },
  '& li li p': {
    paddingLeft: 20
  },
  '.nested': {
    ol: {
      counterReset: 'letter',
      listStyleType: 'lower- alpha',
      li: {
        counterIncrement: 'letter',
        counterReset: 'item'
      },
      'li:before': {
        content: '"(" counter(letter, lower-alpha) ") "'
      }
    },
    'li:before': {
      content: 'counters(item, ".") " "',
      counterIncrement: 'item'
    }
  }
}))

export const InnerList = styled('div')(({ theme }) => ({
  marginBottom: 0,
  marginLeft: 20,
  '& span': {
    display: 'block'
  },
  '& p': {
    marginBottom: 10,
    position: 'relative',
    paddingLeft: 20,
    '&::before': {
      content: '" "',
      position: 'absolute',
      backgroundColor: '#b1c2e6',
      borderRadius: '10px',
      height: '5px',
      width: '5px',
      left: 0,
      top: 10,
      lineHeight: 1
    }
  }
}))

export const Section = styled('section')(({ theme }) => ({
  backgroundColor: theme.palette.wrapper.main,
  height: '100vh',
  position: 'relative',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: 600,
    height: 'auto',
    paddingTop: 50
  }
}))

export const SectionBg = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const SectionBgMobile = styled('div')(({ theme }) => ({
  width: '100%',
  zIndex: 1,
  marginBottom: 40,
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

export const TitleWhite = styled('h2')(({ theme }) => ({
  fontSize: '3.125rem',
  fontWeight: 900,
  margin: '0 0 36px 0',
  padding: 0,
  color: '#fff',
  lineHeight: 1.16,
  [theme.breakpoints.down('md')]: {
    fontSize: '2.25rem',
    lineHeight: 1.22222,
    margin: '0 0 22px 0'
  }
}))

export const SectionDescription = styled('p')(({ theme }) => ({
  fontSize: '1.375rem',
  fontWeight: 700,
  margin: '0 0 1rem 0',
  padding: 0,
  lineHeight: 1.72727,
  color: theme.palette.wrapper.contrastText,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    lineHeight: 1.75
  }
}))

export const ButtonContained = styled(Link)(({ theme }) => ({
  color: '#fff',
  borderColor: '#219CE1',
  backgroundColor: '#219CE1',
  width: '100%',
  maxWidth: '264px',
  padding: '16px 46px',
  borderRadius: 38,
  fontSize: '1.125rem',
  lineHeight: '1.38889',
  display: 'inline-block',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#047abc'
  }
}))

export const ButtonOutlined = styled(Link)(({ theme }) => ({
  color: '#219CE1',
  borderColor: '#219CE1',
  backgroundColor: 'transparent',
  width: '100%',
  maxWidth: '264px',
  padding: '16px 46px',
  borderRadius: 38,
  fontSize: '1.125rem',
  lineHeight: '1.38889',
  display: 'inline-block',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#047abc',
    color: '#fff'
  }
}))
