import * as React from 'react'
import { motion } from 'framer-motion'
import { styled } from '@mui/system'
import { Box, Button, SvgIcon } from '@mui/material'
import LogoSvg from '../../public/images/logo.svg'
import Image from 'next/image'
import { ReserveInviteButton } from '../common/ReserveInviteButton'
import VideoPlayer from './VideoPlayer'
import { fadeUp, fadeUpDelay, fade } from '../../src/animationVariants'

export const ButtonOutlined = styled(Button)(({ theme }) => ({
  color: '#219CE1',
  border: '1px solid #219CE1',
  backgroundColor: 'transparent',
  width: '100%',
  minWidth: '264px',
  maxWidth: '264px',
  padding: '16px 46px',
  borderRadius: 38,
  fontSize: '1.125rem',
  lineHeight: '1.38889',
  display: 'inline-block',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#047abc',
    color: '#fff'
  }
}))

const VideoBox = styled('video')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  objectFit: 'cover',
  top: 0,
  left: 0,
  transform: 'none',
  width: '100%',
  height: '100%'
}))

const ScrollLink = styled('span')(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  cursor: 'pointer',
  position: 'absolute',
  bottom: '5%',
  left: 'calc(50% - 40px)',
  padding: '20px',
  animation: 'MoveUpDown 1.5s linear infinite',
  '& svg': {
    width: 30,
    height: 27
  },
  '@keyframes MoveUpDown': {
    '0%, 100%': {
      bottom: 0
    },
    '50%': {
      bottom: 40
    }
  }
}))

function ArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d='M16.8301 20.5C14.9056 23.8333 10.0944 23.8333 8.16987 20.5L0.808661 7.75001C-1.11584 4.41668 1.28978 0.250001 5.13878 0.250002L19.8612 0.250003C23.7102 0.250003 26.1158 4.41667 24.1913 7.75L16.8301 20.5Z' fill='white' />
    </SvgIcon>
  )
}

export default function VideoWrap({
  scrollRef,
  CTAButtonRef,
  interactiveFormCoachingRef
}) {
  const [openVideo, setOpenVideo] = React.useState(false)

  const handleClick = () => {
    if (interactiveFormCoachingRef.current) {
      interactiveFormCoachingRef
        .current
        .scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
    }
  }

  const handleClickOpenVideo = () => {
    setOpenVideo(true)
  }
  const handleCloseVideo = () => {
    setOpenVideo(false)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <VideoPlayer
        openVideo={openVideo}
        handleCloseVideo={handleCloseVideo}
      />
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          pb: 10,
          '&:before': {
            background: 'rgb(33, 156, 225)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0
          }
        }}
      >
        <Box mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <motion.div
            variants={fade}
            initial={{ opacity: 0 }}
            whileInView='onscreen'
            viewport={{ once: true }}
          >
            <Image
              src={LogoSvg}
              alt='Altis'
              width={240}
              height={192}
            />
          </motion.div>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <motion.div
            variants={fadeUpDelay}
            initial={{ opacity: 0, y: 100 }}
            whileInView='onscreen'
            viewport={{ once: true }}
          >
            <ButtonOutlined onClick={handleClickOpenVideo}>Watch full video</ButtonOutlined>
          </motion.div>
        </Box>
        <ScrollLink onClick={handleClick}>
          <motion.div
            variants={fade}
            initial={{ opacity: 0 }}
            whileInView='onscreen'
            viewport={{ once: true }}
          >
            <ArrowIcon />
          </motion.div>
        </ScrollLink>
      </Box>
      <VideoBox
        autoPlay='autoplay'
        loop='loop'
        muted
        playsInline
        poster='https://storage.googleapis.com/altis_ai-cdn/www/images/bg-section-main-poster.jpg'
      >
        <source
          type='video/mp4'
          src={require('../../public/video/bg-section-main.mp4')}
        />
      </VideoBox>
    </Box>
  )
}
