import React, { useState } from 'react'
import { styled } from '@mui/system'
import { useFormik } from 'formik'
import { Grid, Container, TextField, Button, Box } from '@mui/material'
import axios from 'axios'
import { formSchema } from '../../helpers/validation'
import * as Sentry from '@sentry/nextjs'

const ColorWrap = styled(Box)(({ theme }) => ({
  background: '#f2f2f2',
  marginBottom: 50
}))

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center'
}))

export default function SignUpWrap() {
  const [isEmailSignedUp, setIsEmailSignedUp] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: formSchema,
    onSubmit: async ({ email }, { setFieldError }) => {
      try {
        await axios.post('/api/signUpForUpdates', { email })
        setIsEmailSignedUp(true)
        
      } catch (e) {
        let errorMessage = 'Something went wrong'

        if (!e.response || !e.response.data || !e.response.data.type) {
          Sentry.captureException(e)
          setFieldError('email', errorMessage)
          return
        }
        
        if (e.response.data.type === 'EmailAlreadySignedUp') errorMessage = 'This email is already signed up'
        if (e.response.data.type === 'EmailShouldBeValid') errorMessage = 'Email should be valid'

        setFieldError('email', errorMessage)
      }
    }
  })

  return (
    <ColorWrap>
      <Container maxWidth='xl' sx={{ py: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Box component='h3' sx={{ fontSize: '1.75rem', m: '0 0 0.4rem', fontWeight: 800 }}>Sign up for updates</Box>
            <Box>Stay up to date on product updates</Box>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isEmailSignedUp
              ? <Box>Thank you!</Box>
              : <Form onSubmit={formik.handleSubmit} sx={{ minHeight: '70px', paddingTop: '10px', alignItems: 'flex-start' }}>
                <TextField
                  type='email'
                  id='email'
                  placeholder='Email'
                  size='small'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ flex: 1, '& input': { background: '#fff', borderRadius: 1, height: 28 } }}
                />
                <Button
                  type='submit'
                  size='large'
                  variant='outlined'
                  color='secondary'
                  sx={{
                    ml: 3,
                    height: 47,
                    fontWeight: 700,
                    padding: '5px 40px',
                    borderRadius: '38px',
                    fontSize: '1.125rem'
                  }}
                >
                  Sign Up
                </Button>
              </Form>
            }
          </Grid>
        </Grid>
      </Container>
    </ColorWrap>
  )
}
