import * as React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { Box, Container } from '@mui/material'
import { SectionBg, Section, SectionDescription, TitleWhite, SectionBgMobile } from '../common/Typography'
import { fadeUp, fadeRight } from '../../src/animationVariants'
import Lottie from 'lottie-react'
import animationData from '../../public/animations/bg-section-set-blue-02.json'
import { LottieWrap } from '../common/LottieStyled'
import CoverImg from '../../public/images/bg-section-set-blue-02_v3.jpg'
import CoverImgMobile from '../../public/images/bg-section-set-blue-02-mob.jpg'
import ObjectImg from '../../public/images/section-set-blue-02-decoration.png'
import Parallax from '../common/Parallax'

const interactivity = {
  mode: 'scroll',
  actions: [
    {
      visibility: [0, 0.3],
      type: 'play',
      frames: [0]
    }
  ]
}

export default function HyperPersonalInstruction () {
  return (
    <Section>
      <Container maxWidth='xl' sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', zIndex: 10 }}>
          <Box sx={{ maxWidth: { xs: '100%', md: '38%' } }}>
            <motion.div
              variants={fadeRight}
              initial={{ opacity: 0, x: -50 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <TitleWhite>Hyper-Personal Instruction</TitleWhite>
            </motion.div>
            <motion.div
              variants={fadeUp}
              initial={{ opacity: 0, y: 50 }}
              whileInView='onscreen'
              viewport={{ once: false }}
            >
              <SectionDescription>Altis uses powerful AI to put an elite personal trainer in the convenience of your home. No cookie-cutter programs or canned content. Every single rep, set, and exercise of each workout is customized, calibrated, and optimized for you. With Altis, you will always do what is best for your body.</SectionDescription>
            </motion.div>
          </Box>
        </Box>
      </Container>
      <SectionBg>
        <Image
          alt='Altis'
          src={CoverImg}
          layout='fill'
          objectFit='contain'
          quality={100}
        />
        <Parallax offset={100} top='100px'>
          <Image
            alt='Altis'
            src={ObjectImg}
            layout='fill'
            objectFit='contain'
            quality={80}
            priority
          />
        </Parallax>
        <LottieWrap>
          <Lottie animationData={animationData} interactivity={interactivity} speed={1} />
        </LottieWrap>
      </SectionBg>
      <SectionBgMobile>
        <Image
          alt='Altis'
          src={CoverImgMobile}
          layout='responsive'
          objectFit='contain'
          quality={80}
        />
      </SectionBgMobile>
    </Section>
  )
}
