import * as React from 'react'
import { styled } from '@mui/system'
import { IconButton, Box, Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const VideoBox = styled('video')(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  objectFit: 'contain',
  top: 0,
  left: 0,
  transform: 'none',
  width: '100%',
  height: '100%'
}))

export default function VideoPlayer ({ openVideo, handleCloseVideo }) {
  const handleClose = () => {
    handleCloseVideo()
  }
  
  return (
    <Dialog
      fullScreen
      open={openVideo}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': { background: '#000' }
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <VideoBox
          autoPlay='autoplay'
          loop='loop'
          playsInline
        >
          <source
            type='video/mp4'
            src='https://storage.googleapis.com/altis_ai-cdn/ces/altis-fullVideo.mp4'
          />
        </VideoBox>
      </Box>
      <IconButton
        size='large'
        onClick={handleClose}
        sx={{ position: 'absolute', top: 20, right: 20, zIndex: 100, color: 'grey.100' }}
      >
        <CloseIcon size='large' />
      </IconButton>
    </Dialog>
  )
}
